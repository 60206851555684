import { MotionImage } from "components/MotionImage";
import { motion } from "framer-motion";
import React from "react";

export function Photo({
  bgColor,
  style = null,
  className = "",
  offsetX = -16,
  offsetY = -16,
  src,
  children = null,
}) {
  return (
    <div style={style} className={className}>
      <div className="relative">
        <motion.div
          style={{ background: bgColor, paddingBottom: "133.92857142857144%" }}
          initial={{ opacity: 0, x: 0, y: 0, scale: 1.1 }}
          animate={{
            opacity: 1,
            x: 0,
            y: 0,
            scale: 1,
            transition: { duration: 0.5 },
          }}
        />
        <motion.div className="absolute inset-0" />
        <MotionImage
          src={src}
          initial={{ opacity: 0, x: 0, y: 0, scale: 1 }}
          animate={{
            opacity: 1,
            x: offsetX,
            y: offsetY,
            scale: 1,
            transition: { duration: 0.6, delay: 0.4 },
          }}
          className={`absolute block inset-0 max-w-full h-auto`}
        />
        {children}
      </div>
    </div>
  );
}
